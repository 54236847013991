import "./App.scss";

import ReactDOM from "react-dom";
import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
  RouterProvider,
} from "react-router-dom";
import {
  appliedConfig,
  introBackgroundConfig,
  themeConfig,
} from "./config/commonConfig";

import { Impressum } from "./components/impressum";
import { Datenschutz } from "./components/datenschutz";
import { StartPage } from "./components/StartPage";
import { Tooltip } from "./components/Tooltip";
import CallToAction from "./components/CallToAction";
import FAQs from "./components/FAQs";
import FullScreenVideoBgIntro from "./components/themes/fullScreen/IntroVideoBg";
import FullScreenHeader from "./components/themes/fullScreen/Header";
import FullScreenDefaultIntro from "./components/themes/fullScreen/IntroDefault";
import FullScreenImgBgIntro from "./components/themes/fullScreen/IntroImageBg";
import BottomHeader from "./components/themes/bottomHeader/Header";
import StandardMenuHeader from "./components/themes/StandardMenu/Header";
import BottomHeaderDefaultIntro from "./components/themes/bottomHeader/IntroDefault";
import BottomHeaderImgBgIntro from "./components/themes/bottomHeader/IntroImageBg";
import BottomHeaderVideoBgIntro from "./components/themes/bottomHeader/IntroVideoBg";
import StandardMenuDefaultIntro from "./components/themes/StandardMenu/IntroDefault";
import StandardMenuImgBgIntro from "./components/themes/StandardMenu/IntroImageBg";
import StandardMenuVideoBgIntro from "./components/themes/StandardMenu/IntroVideoBg";
import ReactGA from "react-ga4";
import { useEffect } from "react";
import { getLanguage, I18N, setLanguage } from "./components/translations/i18n";
export default function App() {

  function detectLanguageAndStore() {
    const userLanguage = navigator.language.toLowerCase();
    const languageCode = userLanguage.startsWith('de') ? 'de' : 'en';
    let currentLanguage = getLanguage()
    if (currentLanguage !== languageCode) {
      console.log('Sprache erkannt')
      setLanguage(languageCode)
      window.location.reload();
    }
  };

  useEffect(() => {
    try {
      detectLanguageAndStore();
      const TRACKING_ID = "G-PFFMRWQN28'"; // OUR_TRACKING_ID
      ReactGA.initialize(TRACKING_ID, {
        debug: false,
      });
    } catch (err) {
      console.log(err)
    }
  }, [])

  const appliedTheme = appliedConfig.appliedTheme;
  const appliedIntro = appliedConfig.appliedIntro;

  const getBottomHeaderIntro = () => {
    if (appliedIntro === introBackgroundConfig.default) {
      return <BottomHeaderDefaultIntro></BottomHeaderDefaultIntro>;
    } else if (appliedIntro === introBackgroundConfig.image) {
      return <BottomHeaderImgBgIntro></BottomHeaderImgBgIntro>;
    } else {
      return <BottomHeaderVideoBgIntro></BottomHeaderVideoBgIntro>;
    }
  };

  const router = createBrowserRouter(
    createRoutesFromElements(
      <Route path="/"  >
        <Route path="/" element={<StartPage />} />
        <Route path="/impressum" element={<Impressum />} />
        <Route path="/datenschutz" element={<Datenschutz />} />

      </Route>
    )
  );

  return (
    <div id="main-wrapper" style={{ backgroundColor: '#000' }}>
      <RouterProvider router={router} />
    </div>
  );
}
