import React from "react";
import { useNavigate } from "react-router-dom";

const Footer = () => {
  const navigate = useNavigate();
  return (
    <footer id="footer" className="section bg-dark text-white">
      <div className="container">
        <div className="row">
          <div className="col-lg-6 text-center text-lg-start wow fadeInUp">
            <p className="mb-2 mb-lg-0">
              Copyright © 2023{" "}
              <a className="fw-600" href="/">
                KFM Digital minds GmbH<br></br>
              </a>
              All Rights Reserved.
            </p>
          </div>
          <div className="col-lg-6 wow fadeInUp">
            <p onClick={() => navigate('/impressum')} className="mb-0 text-center text-lg-end text-primary fw-600" style={{ textDecoration: 'underline' }}>
              Impressum
            </p>
            <p onClick={() => navigate('/datenschutz')} className="mb-0 text-center text-lg-end text-primary fw-600" style={{ textDecoration: 'underline' }}>
              Datenschutz
            </p>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
