
import WOW from "wowjs";
import AboutUs from "./About";
import Services from "./Services";
import Resume from "./Resume";
import Portfolio from "./Portfolio";
import Testimonials from "./Testimonials";
import Contact from "./Contact";
import Footer from "./Footer";
import React, { useEffect, useState } from "react";
import {
    appliedConfig,
    introBackgroundConfig,
    themeConfig,
} from "../config/commonConfig";
import { Tooltip } from "./Tooltip";
import CallToAction from "./CallToAction";
import FAQs from "./FAQs";
import FullScreenVideoBgIntro from "./themes/fullScreen/IntroVideoBg";
import FullScreenHeader from "./themes/fullScreen/Header";
import FullScreenDefaultIntro from "./themes/fullScreen/IntroDefault";
import FullScreenImgBgIntro from "./themes/fullScreen/IntroImageBg";
import BottomHeader from "./themes/bottomHeader/Header";
import StandardMenuHeader from "./themes/StandardMenu/Header";
import BottomHeaderDefaultIntro from "./themes/bottomHeader/IntroDefault";
import BottomHeaderImgBgIntro from "./themes/bottomHeader/IntroImageBg";
import BottomHeaderVideoBgIntro from "./themes/bottomHeader/IntroVideoBg";
import StandardMenuDefaultIntro from "./themes/StandardMenu/IntroDefault";
import StandardMenuImgBgIntro from "./themes/StandardMenu/IntroImageBg";
import StandardMenuVideoBgIntro from "./themes/StandardMenu/IntroVideoBg";
import ReactGA from "react-ga4";

export const StartPage = () => {

    const appliedTheme = appliedConfig.appliedTheme;
    const appliedIntro = appliedConfig.appliedIntro;

    const handleNavClick = (section) => {
        document.getElementById(section).scrollIntoView({ behavior: "smooth" });
    };

    const [scrollTopVisible, setScrollTopVisible] = useState(false);

    useEffect(() => {
        new WOW.WOW({
            live: false,
        }).init();

    }, []);

    const checkScrollTop = () => {
        let scrollTopBtn = document.getElementById("back-to-top");

        if (scrollTopBtn) {
            if (
                document.body.scrollTop > 400 ||
                document.documentElement.scrollTop > 400
            ) {
                setScrollTopVisible(true);
            } else {
                setScrollTopVisible(false);
            }
        }
    };

    if (typeof window !== "undefined") {
        window.addEventListener("scroll", checkScrollTop);
    }

    const getHeader = () => {

        // return <BottomHeader></BottomHeader>;

        return (
            <FullScreenHeader
                textWhite={appliedIntro === introBackgroundConfig.image}
            ></FullScreenHeader>
        )

        // return <StandardMenuHeader></StandardMenuHeader>;

    };

    const getBottomHeaderIntro = () => {
        if (appliedIntro === introBackgroundConfig.default) {
            return <BottomHeaderDefaultIntro></BottomHeaderDefaultIntro>;
        } else if (appliedIntro === introBackgroundConfig.image) {
            return <BottomHeaderImgBgIntro></BottomHeaderImgBgIntro>;
        } else {
            return <BottomHeaderVideoBgIntro></BottomHeaderVideoBgIntro>;
        }
    };

    const getFullScreenIntro = () => {
        if (appliedIntro === introBackgroundConfig.default) {
            return <FullScreenDefaultIntro></FullScreenDefaultIntro>;
        } else if (appliedIntro === introBackgroundConfig.image) {
            return <FullScreenImgBgIntro></FullScreenImgBgIntro>;
        } else {
            return <FullScreenVideoBgIntro></FullScreenVideoBgIntro>;
        }
    };

    const getStandardMenuIntro = () => {
        if (appliedIntro === introBackgroundConfig.default) {
            return <StandardMenuDefaultIntro></StandardMenuDefaultIntro>;
        } else if (appliedIntro === introBackgroundConfig.image) {
            return <StandardMenuImgBgIntro></StandardMenuImgBgIntro>;
        } else {
            return <StandardMenuVideoBgIntro></StandardMenuVideoBgIntro>;
        }
    };


    return (<div style={{ position: "relative" }}>
        <div id="main-wrapper">

            <div id="content" role="main">
                {appliedTheme === themeConfig.FullScreenMenu &&
                    getFullScreenIntro()}
                {appliedTheme === themeConfig.StandardMenu &&
                    getStandardMenuIntro()}

                {getHeader()}

                <AboutUs></AboutUs>
                <Services></Services>
                {/* <Resume></Resume> */}
                <Portfolio></Portfolio>
                <CallToAction></CallToAction>
                <FAQs></FAQs>
                {/* <Testimonials></Testimonials> */}
                <Contact></Contact>
            </div>
            <Footer handleNavClick={handleNavClick}></Footer>
        </div>
        {/* back to top */}
        <Tooltip text="Back to Top" placement="left">
            <span
                id="back-to-top"
                className="rounded-circle"
                style={{ display: scrollTopVisible ? "inline" : "none" }}
                onClick={() => {
                    window.scrollTo({ top: 0, behavior: "smooth" });
                }}
            >
                <i className="fas fa-arrow-up"></i>
            </span>
        </Tooltip>
    </div>)

}