import React, { useEffect, useState } from "react";
import { Link } from "react-scroll";
import { scrollDuration } from "../../../config/commonConfig";
import { Tooltip } from "../../Tooltip";
import { useNavigate } from "react-router-dom";
import { I18N } from "../../translations/i18n";

const FullScreenHeader = ({ textWhite, notStartPage }) => {
  const [stickyHeader, setStickyHeader] = useState(false);
  const [whiteMode, setWhiteMode] = useState(false);
  const [isNavModalClose, setIsNavModalClose] = useState(true);
  const navigate = useNavigate();

  let whiteElements = [{ name: 'services', iswhite: true }, { name: 'faq', iswhite: true }, { name: 'contact', iswhite: true }, { name: 'home', iswhite: false }, { name: 'about', iswhite: false }, { name: 'portfolio', iswhite: false }, { name: 'call2Action', iswhite: false }]

  function fillElementsWithPosition() {

    whiteElements.forEach(ele => {
      var element = document.getElementById(ele.name);
      // Position des Elements relativ zum Viewport erhalten
      if (element !== null && element !== undefined) {
        ele.from = element.offsetTop - 60

        ele.to = element.clientHeight - 60 + ele.from + parseFloat(getComputedStyle(element).padding)
      }
    })
    whiteElements = whiteElements.sort((a, b) => a - b)
  }

  useEffect(() => {
    setTimeout(() => {
      fillElementsWithPosition()
      window.addEventListener("scroll", checkScrollTop);
    }, 1000)
  }, [])


  const checkScrollTop = () => {
    let header = document.getElementsByClassName("primary-menu");

    if (header) {
      const currentPosition = document.documentElement.scrollTop
      const foundCurrentElement = whiteElements.find(ele => ele.from <= currentPosition && ele.to >= currentPosition)
      if (foundCurrentElement) {
        setWhiteMode(foundCurrentElement.iswhite)
      } else {
        setWhiteMode(false)

      }
      // if (
      //   whitePositions.includes(document.documentElement.scrollTop)
      // ) {
      //   setWhiteMode(true)
      // }
      // if (
      //   blackPositions.includes(document.documentElement.scrollTop)
      // ) {
      //   setWhiteMode(false)
      // }
    }
  };
  //box-shadow: 0px 0px 10px rgba(250, 250, 250, 0.03);


  return (
    <header id="header" className="sticky-top" style={{ backgroundColor: whiteMode ? '#fff' : '#000', top: -1, marginTop: -1 }}>
      {/* Navbar */}
      <nav
        className={
          "primary-menu navbar navbar-expand-none navbar-overlay bg-transparent border-bottom-0 text-5 fw-600 bg-black" +
          (whiteMode ? " bg-black " : "bg-white") +
          (textWhite
            ? "navbar-overlay-dark"
            : "navbar-overlay navbar-text-light")
        }
      >
        <div className="container">
          {/* Logo */}
          <Link
            className="logo"
            title="Callum"
            smooth="easeInOutQuint"
            duration={scrollDuration}
            style={{ cursor: "pointer" }}
            to="home"
            href={notStartPage ? 'https://kfm.digital' : undefined}
            onClick={(e) => {
              e.preventDefault();
              setIsNavModalClose(true);
            }}
          >
            <img
              onClick={() => navigate('/')}
              src={"images/LOGO.svg"}
              alt="Callum"
            />
          </Link>
          {/* Logo End */}
          <a
            className={"ms-auto me-2 " + (textWhite ? "text-white headerPhone" : "text-primary headerPhone")}
            style={{ textDecoration: 'none' }}
            href="tel:+(491)7673550100"
          >
            <span className="me-2">
              <i className="fas fa-phone" />
            </span>
            +49 176 7355 0100
          </a>
          <button
            className={
              "navbar-toggler collapsed " + (isNavModalClose ? "" : "show")
            }
            type="button"
            onClick={() => setIsNavModalClose(!isNavModalClose)}
          >
            <span className={"bg-primary"} />
            <span className={"bg-primary"} />
            <span className={"bg-primary"} />
          </button>
          <div
            id="header-nav"
            className={
              "collapse navbar-collapse  " + (isNavModalClose ? "" : "show")
            }
          >
            <div className="d-flex flex-column h-100 align-items-center justify-content-center">
              <ul className="navbar-nav">
                <li className="nav-item">
                  <Link
                    className="nav-link"
                    title="Callum"
                    smooth="easeInOutQuint"
                    duration={scrollDuration}
                    style={{ cursor: "pointer" }}
                    to="home"

                    onClick={(e) => {
                      setIsNavModalClose(true);
                      navigate('/')
                    }}
                  >
                    <img
                      style={{ height: 50 }}
                      src={"images/LOGO.svg"}
                      alt="Callum"
                    />
                  </Link>
                </li>
                <li className="nav-item">
                  <Link
                    className="nav-link "
                    smooth="easeInOutQuint"
                    duration={scrollDuration}
                    style={{ cursor: "pointer" }}
                    activeClass="active"
                    spy
                    to="about"
                    onClick={(e) => {
                      e.preventDefault();
                      navigate('/#about')
                      setIsNavModalClose(true);
                    }}
                  >
                    {I18N('about.title')}
                  </Link>
                </li>
                <li className="nav-item">
                  <Link
                    className="nav-link "
                    smooth="easeInOutQuint"
                    duration={scrollDuration}
                    style={{ cursor: "pointer" }}
                    activeClass="active"
                    spy
                    to="services"
                    onClick={(e) => {
                      e.preventDefault();
                      setIsNavModalClose(true);
                    }}
                  >
                    {I18N('services.title')}
                  </Link>
                </li>
                {/* <li className="nav-item">
                  <Link
                    className="nav-link "
                    smooth="easeInOutQuint"
                    duration={scrollDuration}
                    style={{ cursor: "pointer" }}
                    activeClass="active"
                    spy
                    to="resume"
                    onClick={(e) => {
                      e.preventDefault();
                      setIsNavModalClose(true);
                    }}
                  >
                    Resume
                  </Link>
                </li> */}
                <li className="nav-item">
                  <Link
                    className="nav-link "
                    smooth="easeInOutQuint"
                    duration={scrollDuration}
                    style={{ cursor: "pointer" }}
                    activeClass="active"
                    spy
                    to="portfolio"
                    onClick={(e) => {
                      e.preventDefault();
                      setIsNavModalClose(true);
                    }}
                  >
                    {I18N('portfolio.title')}
                  </Link>
                </li>
                <li className="nav-item">
                  <Link
                    className="nav-link "
                    smooth="easeInOutQuint"
                    duration={scrollDuration}
                    style={{ cursor: "pointer" }}
                    activeClass="active"
                    spy
                    to="faq"
                    onClick={(e) => {
                      e.preventDefault();
                      setIsNavModalClose(true);
                    }}
                  >
                    FAQ
                  </Link>
                </li>
                {/* <li className="nav-item">
                  <Link
                    className="nav-link "
                    smooth="easeInOutQuint"
                    duration={scrollDuration}
                    style={{ cursor: "pointer" }}
                    activeClass="active"
                    spy
                    to="testimonial"
                    onClick={(e) => {
                      e.preventDefault();
                      setIsNavModalClose(true);
                    }}
                  >
                    Client Speak
                  </Link>
                </li> */}
                <li className="nav-item">
                  <Link
                    className="nav-link "
                    smooth="easeInOutQuint"
                    duration={scrollDuration}
                    style={{ cursor: "pointer" }}
                    activeClass="active"
                    spy
                    to="contact"
                    onClick={(e) => {
                      e.preventDefault();
                      setIsNavModalClose(true);
                    }}
                  >
                    {I18N('contact.title')}
                  </Link>
                </li>
                <li className="nav-item">
                  <Link
                    className="nav-link "
                    smooth="easeInOutQuint"
                    duration={scrollDuration}
                    style={{ cursor: "pointer" }}
                    activeClass="active"
                    spy
                    to=""
                    onClick={(e) => {
                      e.preventDefault();
                      setIsNavModalClose(true);
                      navigate('/impressum')
                    }}
                  >
                    {I18N('imprint.title')}
                  </Link>
                </li>
                <li className="nav-item">
                  <Link
                    className="nav-link "
                    smooth="easeInOutQuint"
                    duration={scrollDuration}
                    style={{ cursor: "pointer" }}
                    activeClass="active"
                    to=""
                    spy
                    onClick={(e) => {
                      e.preventDefault();
                      navigate('/datenschutz')
                      setIsNavModalClose(true);
                    }}
                  >
                    {I18N('dataPrivacy.title')}
                  </Link>
                </li>
              </ul>
              <ul className="social-icons social-icons-light social-icons-lg social-icons-light d-inline-flex mt-4">

                <li className="social-icons-instagram">
                  <Tooltip text="Instagram" placement="top">
                    <a
                      href="https://instagram.com/kfmdigitalminds/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <i className="fab fa-instagram" />
                    </a>
                  </Tooltip>
                </li>
                <li className="social-icons-linkedin">
                  <Tooltip text="Linkedin" placement="top">
                    <a
                      href="https://linkedin.com/in/kemal-fathulla-kfmdigitalminds"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <i className="fab fa-linkedin-in" />
                    </a>
                  </Tooltip>
                </li>
                {/* <li className="social-icons-github">
                  <Tooltip text="Github" placement="top">
                    <a
                      href="https://github.com/kfmdm/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <i className="fab fa-github" />
                    </a>
                  </Tooltip>
                </li> */}
              </ul>
            </div>
          </div>
        </div>
      </nav>
      {/* Navbar End */}
    </header>
  );
};

export default FullScreenHeader;
