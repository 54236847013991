import React from "react";
import { Link } from "react-scroll";
import { Parallax } from "react-parallax";
import { scrollDuration } from "../config/commonConfig";

import ReactGA from "react-ga4";
import { I18N } from "./translations/i18n";
const CallToAction = () => {
  function safeEvent() {
    ReactGA.event({
      category: 'kontakt',
      action: 'kontakt',
      label: 'kontakt'
    })
  }

  return (
    <section id="call2Action" >
      <div className="hero-wrap bg-black">
        <div className="hero-mask opacity-8 bg-black" />

        <div className="hero-content section">
          <div className="container text-center py-5 wow fadeInUp">
            <h2 className="text-10 fw-600 text-white mb-5">
              {I18N('call2Action.headline')}
            </h2>
            <a
              className="btn btn-primary rounded-0 smooth-scroll wow rubberBand text-white"
              target="_blank"
              href="https://calendly.com/kfm-dm/30min"
              onClick={() => safeEvent()}

            >

              {I18N('call2Action.button')}
            </a>{" "}
          </div>
        </div>
      </div>
    </section>
  );
};

export default CallToAction;
