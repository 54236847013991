import React, { useRef, useState } from "react";
import emailjs from "@emailjs/browser";
import { ToastContainer, toast } from "react-toastify";
import axios from 'axios'
import "react-toastify/dist/ReactToastify.css";
import { Tooltip } from "./Tooltip";
import { I18N } from "./translations/i18n";

const Contact = () => {
  const form = useRef();

  const [sendingMail, setSendingMail] = useState(false);
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');

  const sendEmail = (e) => {
    e.preventDefault();
    setSendingMail(true);

    axios.post('https://website-backend.kfm.digital/emailMe', {
      name: name,
      email: email,
      // phone: this.state.phone ? this.state.phone : 'Nicht angegeben',
      message: message
    }).then(res => {
      document.getElementById("contact-form").reset();
      setSendingMail(false);
      setName('')
      setEmail('')
      setMessage('')
    })

  };


  // const sendEmail = (e) => {
  //   e.preventDefault();
  //   setSendingMail(true);
  //   emailjs
  //     .sendForm(
  //       "service_i8Fk3ms",
  //       "template_siFcin9",
  //       form.current,
  //       "c9HsFgGF0tFWyVnAL"
  //     )
  //     .then(
  //       (result) => {
  //         document.getElementById("contact-form").reset();
  //         toast.success("Message sent successfully!", {
  //           position: "top-right",
  //           autoClose: 5000,
  //           hideProgressBar: false,
  //           closeOnClick: true,
  //           pauseOnHover: true,
  //           draggable: true,
  //           progress: undefined,
  //           theme: "light",
  //         });
  //         console.log(result.text);
  //         setSendingMail(false);
  //       },
  //       (error) => {
  //         toast.error("Something went wrong!", {
  //           position: "top-right",
  //           autoClose: 5000,
  //           hideProgressBar: false,
  //           closeOnClick: true,
  //           pauseOnHover: true,
  //           draggable: true,
  //           progress: undefined,
  //           theme: "light",
  //         });
  //         console.log(error.text);
  //         setSendingMail(false);
  //       }
  //     );
  // };



  return (
    <section id="contact" className="section bg-white">
      <div className="container">

        <p className="text-center mb-2 wow fadeInUp">
          <span className="bg-primary text-dark px-2">{I18N('contact.title')}</span>
        </p>
        <div className="row">

          <div
            className="col-lg-6 ms-auto mt-5 mt-lg-0 wow fadeInUp"
            data-wow-delay="0.3s"
          >
            <h2 className="text-10 fw-600 text-center text-lg-start mb-5">
              {I18N('contact.headline')}
            </h2>
            {/* Contact Form */}
            <form
              id="contact-form"
              className="form-border"
              method="post"
              onSubmit={sendEmail}
            >
              <div className="row g-4">
                <div className="col-12">
                  <label className="form-label" htmlFor="name">
                    {I18N('contact.form.name')}
                  </label>
                  <input
                    id="name"
                    name="user_name"
                    type="text"
                    onChange={(value) => setName(value.target.value)}
                    value={name}

                    className="form-control py-1 bg-light"
                    required
                  />
                </div>
                <div className="col-12">
                  <label className="form-label" htmlFor="email">
                    {I18N('contact.form.mail')}
                  </label>
                  <input
                    id="email"
                    name="user_email"
                    type="email"
                    onChange={(value) => setEmail(value.target.value)}
                    value={email}
                    className="form-control py-1 bg-light "
                    required
                  />
                </div>
                <div className="col-12">
                  <label className="form-label" htmlFor="form-message">
                    {I18N('contact.form.info')}
                  </label>
                  <textarea
                    id="form-message"
                    name="message"
                    className="form-control py-1 bg-light"
                    rows={4}
                    onChange={(value) => setMessage(value.target.value)}
                    value={message}
                    required
                  />
                </div>
                <div className="col-12 text-center text-lg-start">
                  <button
                    id="submit-btn"
                    className="btn btn-primary rounded-0 text-white"
                    type="submit"
                  >
                    {sendingMail ? (
                      <>
                        <span
                          role="status"
                          aria-hidden="true"
                          className="spinner-border spinner-border-sm align-self-center me-2"
                        ></span>
                        Sendet......
                      </>
                    ) : (
                      <>
                        Senden{" "}
                        <span className="ms-3">
                          <i className="fas fa-arrow-right" />
                        </span>
                      </>
                    )}
                  </button>
                </div>
                <ToastContainer />
              </div>
            </form>
            {/* Contact Form end */}
          </div>
          <div className="col-lg-5 text-center text-lg-start wow fadeInUp">
            <h2 className="text-10 fw-600 mb-5">{I18N('contact.title')}</h2>
            <p className="text-5 mb-5">
              {I18N('contact.welcomeText')}
            </p>
            <h3 className="text-5 fw-600">{I18N('contact.address')}</h3>
            <address className="text-4">
              Mombacherstr.68, 55122 Mainz
            </address>
            <h3 className="text-5 fw-600">Tel.:</h3>
            <a href="tel:+4917673550100" className="text-4">+49 176 7355 0100</a>
            <ul className="social-icons social-icons-lg justify-content-center justify-content-lg-start mt-5">
              <li className="social-icons-linkedIn">
                <Tooltip text="LinkedIN" placement="top">
                  <a
                    href="https://linkedin.com/in/kemal-fathulla-kfmdigitalminds"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <i className="fab fa-linkedin-in" />
                  </a>
                </Tooltip>
              </li>
              {/* <li className="social-icons-facebook">
                <Tooltip text="Facebook" placement="top">
                  <a
                    href="http://www.facebook.com/harnishdesign/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <i className="fab fa-facebook" />
                  </a>
                </Tooltip>
              </li> */}
              <li className="social-icons-instagram">
                <Tooltip text="Instagram" placement="top">
                  <a
                    href="https://instagram.com/kfmdigitalminds/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <i className="fab fa-instagram" />
                  </a>
                </Tooltip>
              </li>
              <li className="social-icons-github">
                <Tooltip text="Github" placement="top">
                  <a
                    href="https://github.com/kfmdm/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <i className="fab fa-github" />
                  </a>
                </Tooltip>
              </li>
              {/* <li className="social-icons-dribbble">
                <Tooltip text="Dribbble" placement="top">
                  <a
                    href="http://www.dribbble.com/harnishdesign/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <i className="fab fa-dribbble" />
                  </a>
                </Tooltip>
              </li> */}
            </ul>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Contact;
