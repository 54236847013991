import React, { useCallback } from "react";
import { Link } from "react-scroll";
import { scrollDuration } from "../config/commonConfig";
import Particles from "react-particles";
import { loadFull } from "tsparticles";
import { params } from './jsons'

export function ParticlesBg(props) {

    const particlesInit = useCallback(async engine => {
        // console.log(engine);
        // you can initiate the tsParticles instance (engine) here, adding custom shapes or presets
        // this loads the tsparticles package bundle, it's the easiest method for getting everything ready
        // starting from v2 you can add only the features you need reducing the bundle size
        await loadFull(engine);
    }, []);

    const particlesLoaded = useCallback(async container => {
        // console.log('HELLOO')
        // await console.log(container);
    }, []);

    return (
        <Particles id={props.id} height={'100%'} width={'100%'} fullScreen={false} style={{ zIndex: 0, position: 'absolute', height: '100%', width: '100%' }} options={params.header} init={particlesInit} loaded={particlesLoaded} />
    );
};



