import React from "react";
import { Link } from "react-scroll";
import { scrollDuration } from "../config/commonConfig";
import { ParticlesBg } from "./ParticlesBg";
import ReactGA from "react-ga4";
import { I18N } from "./translations/i18n";

const AboutUs = () => {

  function safeEvent() {
    ReactGA.event({
      category: 'kontakt',
      action: 'kontakt',
      label: 'kontakt'
    })
  }

  return (
    <section id="about" className="section bg-black" style={{
      position: 'relative',
      marginTop: -1
    }}>
      <ParticlesBg id={'aboutBg'} />
      <div className="container " style={{ zIndex: 2, position: 'relative' }}>
        {/* Heading */}
        < p className="text-center mb-2 wow fadeInUp">
          <span className="bg-primary px-2">{I18N('about.title')}</span>
        </p>
        {/* <h2 className="text-10 fw-600 text-center  text-white  mb-5 wow fadeInUp">
          Erfahre mehr über uns
        </h2> */}
        {/* Heading end*/}
        <div className="row">
          <div className="col-lg-8 text-center text-lg-start wow fadeInUp">
            <h2 className="text-8 fw-400 mb-3 text-white">

              {I18N("about.headline.headline")}
              <span className="fw-500 border-bottom border-3 border-primary">
                {I18N("about.headline.headlineUnderlinedRow1")} <br></br>{I18N("about.headline.headlineUnderlinedRow2")}
              </span>
            </h2>
            <p style={{ marginTop: 55 }} className="text-3 text-secondary">
              {I18N('about.subtitle.normal')}<b className="text-primary">{I18N('about.subtitle.bold')}</b> {I18N('about.subtitle.normal2')} <b>{I18N('about.subtitle.bold2')}</b> & <b>{I18N('about.subtitle.bold3')}</b>.
            </p>
            <Link
              className="btn bg-primary text-white rounded-0 smooth-scroll mt-3"
              smooth="easeInOutQuint"
              duration={scrollDuration}
              style={{ cursor: "pointer", zIndex: 5 }}
              to="contact"
              onClick={() => {
                console.log('WAS GEEEEHT')
                safeEvent()
              }}
            >
              {I18N('contactUs')}
            </Link>
          </div>

        </div>
        {/* <div className="row gy-3 mt-4">
          <div className="col-6 col-lg-3 wow fadeInUp">
            <p className="text-muted fw-500 mb-0">Name:</p>
            <p className="text-4 text-dark fw-600 mb-0">Callum Smith</p>
          </div>
          <div className="col-6 col-lg-3 wow fadeInUp" data-wow-delay="0.2s">
            <p className="text-muted fw-500 mb-0">Email:</p>
            <p className="text-4 fw-600 mb-0">
              <a className="link-dark" href="mailto:chat@callum.com">
                chat@callum.com
              </a>
            </p>
          </div>
          <div className="col-6 col-lg-3 wow fadeInUp" data-wow-delay="0.3s">
            <p className="text-muted fw-500 mb-0">Date of birth:</p>
            <p className="text-4 text-dark fw-600 mb-0">11 November, 1987</p>
          </div>
          <div className="col-6 col-lg-3 wow fadeInUp" data-wow-delay="0.4s">
            <p className="text-muted fw-500 mb-0">From:</p>
            <p className="text-4 text-dark fw-600 mb-0">Los Angeles, USA.</p>
          </div>
        </div> */}
      </div >

    </section >
  );
};

export default AboutUs;
