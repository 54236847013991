import { useEffect } from "react"
import FullScreenHeader from "./themes/fullScreen/Header"
import FullScreenDefaultIntro from "./themes/fullScreen/IntroDefault"
import Footer from "./Footer";
import { Tooltip } from "./Tooltip";
import { I18N } from "./translations/i18n";
export const Datenschutz = () => {

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])
    return (<>
        <FullScreenHeader notStartPage={true} />
        <FullScreenDefaultIntro text={I18N('dataPrivacy.title').toUpperCase()} />
        <div style={{ paddingRight: 12, paddingLeft: 12, color: '#fff' }} className="textBlock\">
            <div dangerouslySetInnerHTML={{ __html: I18N('dataPrivacy.content') }} />
        </div>
        <Footer></Footer>

        {/* back to top */}
        < Tooltip text="Back to Top" placement="left" >
            <span
                id="back-to-top"
                className="rounded-circle"
                style={{ display: "inline" }}
                onClick={() => {
                    window.scrollTo({ top: 0, behavior: "smooth" });
                }}
            >
                <i className="fas fa-arrow-up"></i>
            </span>
        </Tooltip >
    </>)
}