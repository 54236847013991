import React, { useCallback } from "react";
import { Link } from "react-scroll";
import Typewriter from "typewriter-effect";
import { scrollDuration } from "../../../config/commonConfig";
import { ParticlesBg } from '../../ParticlesBg'
import { I18N } from "../../translations/i18n";

const FullScreenDefaultIntro = ({ text }) => {


  return (
    <section
      id="home"
      className="d-flex fullscreen position-relative py-5 bg-black"
      style={{ position: 'relative' }}
    >

      <ParticlesBg id={'headerbg'} />

      <div className="container my-auto py-4">
        <div className="row">

          <div className="col-lg-7 text-center text-lg-start align-self-center order-1 order-lg-0 wow fadeIn">
            {text === undefined &&
              <h1 className="text-12 fw-300 mb-0 text-white ">
                <div >{I18N('headline.firstHeadline')}</div> <div className="text-primary" style={{ display: 'inline' }}> {I18N('headline.secondHeadline')}</div>
              </h1>
            }
            {text !== undefined &&
              <h1 className="text-12 fw-500 mb-0 text-white ">
                <div style={{ display: 'inline' }}>{text}</div>
              </h1>
            }
          </div>
          <div className=" col-lg-5 text-center align-self-center mb-4 mb-lg-0 order-0 order-lg-1">
            <div style={{ overflow: 'hidden' }} className=" bg-black d-inline-block p-3 shadow-lg wow animated">

              <img
                style={{ zIndex: 5, position: 'relative' }}
                className="animated portraitImg"
                src="images/Portrait4.JPG"
                title="I'm Kemal"
                alt="KEMAL FATHULLA MUSTAPHA"
              />

            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default FullScreenDefaultIntro;
