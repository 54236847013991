import React, { useEffect, useRef, useState } from "react";
import Isotope from "isotope-layout";
import ProjectDetailsModal from "./ProjectDetailsModal";
import { ParticlesBg } from "./ParticlesBg";
import ReactGA from "react-ga4";
import { I18N } from "./translations/i18n";
const Portfolio = () => {
  // init one ref to store the future isotope object
  const isotope = useRef();
  // store the filter keyword in a state
  const [filterKey, setFilterKey] = useState("*");
  const [imagesLoaded, setimagesLoaded] = useState(0);
  const [selectedProjectDetails, setSelectedProjectDetails] = useState();
  const [isOpen, setIsOpen] = useState(false);

  const htmlElement = document.getElementsByTagName("html")[0];
  const isRtl = htmlElement.getAttribute("dir") === "rtl";

  const filters = {
    WEB: "Webanwendung",
    NATIVE: "Mobile App",
    SONSTIGE: "Sonstige",
  };

  const types = {
    WEB: "image",
    NATIVE: "video",
    SONSTIGE: "document",
  };

  function safeEvent(project) {
    ReactGA.event({
      category: 'Project ',
      action: 'clicked',
      label: project.title
    })
  }

  const projectsData = [
    {
      title: I18N('portfolio.content.medical.title'),
      type: types.WEB,
      document: {
        projectInfo: I18N('portfolio.content.medical.projectInfo'),

        // client: "Ruby Clinton",
        technologies: "Angular, NestJs, Docker",
        industry: I18N('portfolio.content.medical.industry'),
        date: I18N('portfolio.content.medical.date'),
        sliderImages: [
          "images/projects/inme/c.png",
          "images/projects/inme/c1.png",
          "images/projects/inme/email.png",
          "images/projects/inme/inMe-COMPANY-Zugang-1.jpeg",
        ],
      },
      thumbImage: "images/projects/inme/inMe-COMPANY-Zugang-3.jpeg",
      categories: [filters.DETAILED],
    },

    {
      title: I18N('portfolio.content.dms.title'),
      type: types.WEB,
      document: {
        projectInfo: I18N('portfolio.content.dms.projectInfo'),
        technologies: "React, NestJs, Docker",
        industry: I18N('portfolio.content.dms.industry'),
        date: I18N('portfolio.content.dms.date'),
        sliderImages: [
          "images/projects/DMS/Startseite.png",
          "images/projects/DMS/RL_Detail_Startseite_Light.png",
        ],
      },
      thumbImage: "images/projects/DMS/Dashboard.png",
      categories: [filters.DETAILED],
    },
    {
      title: I18N('portfolio.content.compliness.title'),
      type: types.WEB,
      document: {
        projectInfo:I18N('portfolio.content.compliness.projectInfo'),
        technologies: "React, NestJs, Docker",
        industry: I18N('portfolio.content.compliness.industry'),
        date: I18N('portfolio.content.compliness.date'),
        sliderImages: [
          "images/projects/compliness/MeineVideos.png",
          "images/projects/compliness/dashBlack.png",
          "images/projects/compliness/Lernvideos _Quiz_Erfolg.png"
        ],
      },
      thumbImage: "images/projects/compliness/dashwhite.png",
      categories: [filters.DETAILED],
    },
    {
      title: I18N('portfolio.content.corona.title'),
      type: types.WEB,
      document: {
        projectInfo:I18N('portfolio.content.corona.projectInfo'),
        technologies: "Angular, NestJs, Docker",
        industry: I18N('portfolio.content.corona.industry'),
        date: I18N('portfolio.content.corona.date'),
        sliderImages: [
          "images/projects/Corona/corona.png",
          "images/projects/DMS/RL_Detail_Startseite_Light.png",
        ],
      },

      thumbImage: "images/projects/Corona/corona.png",
      categories: [filters.DETAILED],
    },
    {
      title: I18N('portfolio.content.pos.title'),
      type: types.WEB,
      document: {
        projectInfo:I18N('portfolio.content.pos.projectInfo'),
        technologies: "React-Native, Angular, NestJs, Docker, Android(Java)",
        industry: I18N('portfolio.content.pos.industry'),
        date: I18N('portfolio.content.pos.date'),
        sliderImages: [
          "images/projects/order2me/4.png",
          "images/projects/kasse/terminal.png",
        ],
      },
      thumbImage: "images/projects/kasse/kasse.png",
      categories: [filters.DETAILED],
    },
    {
      title: I18N('portfolio.content.pos.title'),
      type: types.WEB,
      document: {
        projectInfo:I18N('portfolio.content.pos.projectInfo'),
        technologies: "React, NestJs, Docker",
        industry: I18N('portfolio.content.pos.industry'),
        date: I18N('portfolio.content.pos.date'),
        sliderImages: [
          "images/projects/order2me/3.png",
          "images/projects/order2me/1.png",
          "images/projects/order2me/4.png",
          "images/projects/order2me/2.png",
        ],
      },
      thumbImage: "images/projects/order2me/5.png",
      categories: [filters.DETAILED],
    },
  ];

  // initialize an Isotope object with configs
  useEffect(() => {
    isotope.current = new Isotope(".portfolio-filter", {
      itemSelector: ".filter-item",
      layoutMode: "masonry",
      originLeft: !isRtl,
    });

    // cleanup
    return () => {
      isotope.current.destroy();
    };
  }, []);

  // handling filter key change
  useEffect(() => {
    if (imagesLoaded) {
      filterKey === "*"
        ? isotope.current.arrange({ filter: `*` })
        : isotope.current.arrange({ filter: `.${filterKey}` });
    }
  }, [filterKey, imagesLoaded]);

  const handleFilterKeyChange = (key) => () => setFilterKey(key);

  const getKeyByValue = (value) => {
    return Object.keys(filters).find((key) => filters[key] === value);
  };

  const getFilterClasses = (categories) => {
    if (categories.length > 0) {
      let tempArray = [];
      categories.forEach((category, index) => {
        tempArray.push(getKeyByValue(category));
      });
      return tempArray.join(" ");
    }
  };

  return (
    <>
      <section id="portfolio" className={"section bg-black"}>
        <ParticlesBg id={'portfolioBG'} />
        <div className={"container"}>
          {/* Heading */}
          <p className="text-center mb-2 wow fadeInUp">
            <span className="bg-primary text-dark px-2">{I18N("portfolio.title")}</span>
          </p>
          <h2 className="text-10 fw-600 text-white text-center mb-5 wow fadeInUp">
            {I18N('portfolio.headline')}
          </h2>
          {/* Heading end*/}
          {/* Filter Menu */}
          {/* <ul
            className={
              "portfolio-menu nav nav-tabs fw-600 justify-content-start justify-content-md-center border-bottom-0 mb-5 wow fadeInUp"
            }
          >
            <li className="nav-item">
              <button
                className={"nav-link " + (filterKey === "*" ? "active" : "")}
                onClick={handleFilterKeyChange("*")}
              >
                Alle
              </button>
            </li>
            {Object.keys(filters).map((oneKey, i) => (
              <li className="nav-item" key={i}>
                <button
                  className={
                    "nav-link " + (filterKey === oneKey ? "active" : "")
                  }
                  onClick={handleFilterKeyChange(oneKey)}
                >
                  {filters[oneKey]}
                </button>
              </li>
            ))}
          </ul> */}
          {/* Filter Menu end */}
          <div className="portfolio wow fadeInUp">
            <div className="row portfolio-filter filter-container g-4">
              {projectsData.length > 0 &&
                projectsData.map((project, index) => (
                  <div
                    className={
                      "col-sm-6 col-lg-4 filter-item " +
                      getFilterClasses(project.categories)
                    }
                    style={{ zIndex: 5 }}
                    key={index}
                  >
                    <div className="portfolio-box">
                      <div className="portfolio-img">
                        <img
                          onLoad={() => {
                            setimagesLoaded(imagesLoaded + 1);
                          }}
                          className="img-fluid d-block portfolio-image"
                          src={project.thumbImage}
                          alt=""
                        />
                        <div
                          className="portfolio-overlay"
                          onClick={() => {
                            setSelectedProjectDetails(projectsData[index]);
                            setIsOpen(true);
                            safeEvent(project)
                          }}
                        >
                          <button className="popup-ajax stretched-link border-0 p-0 ">
                            {" "}
                          </button>
                          <div className="portfolio-overlay-details">
                            {/* <p className="text-primary text-8">
                              {project.type === types.DOCUMENT && (
                                <i className="fas fa-file-alt"></i>
                              )}
                              {project.type === types.IMAGE && (
                                <i className="fas fa-image"></i>
                              )}
                              {project.type === types.VIDEO && (
                                <i className="fas fa-video"></i>
                              )}
                            </p> */}
                            <h5 className="text-white text-5">
                              {project?.title}
                            </h5>
                            <span className="text-light">{project?.document.technologies}</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
            </div>
          </div>
        </div>
      </section>
      {/* Modal */}
      {isOpen && (
        <ProjectDetailsModal
          projectDetails={selectedProjectDetails}
          setIsOpen={setIsOpen}
        ></ProjectDetailsModal>
      )}
    </>
  );
};

export default Portfolio;
