const English = require('./packs/en.json');
const German = require('./packs/de.json');

export const languages = {
    en: English,
    de: German,
};

export function I18N(key, language = 'de', keywords) {
    const keys = key.split('.'); // Split the key into its parts
    let i18nText = languages[getLanguage()];

    for (const k of keys) {
        i18nText = i18nText[k]; // Traverse through the nested JSON structure
        if (!i18nText) {
            console.warn(`${language} : '${key}' is missing`);
            return key; // If the key is not found, return the original key
        }
    }

    if (Array.isArray(i18nText)) {
        // If the value is an array, return it as-is
        return i18nText;
    } else {
        // If the value is not an array, translate it directly
        return translateString(i18nText, keywords);
    }
}

function translateString(text, keywords) {
    let translatedText = text;

    if (keywords !== undefined) {
        keywords.forEach((keyword) => {
            translatedText = translatedText.replace(`{${keyword.key}}`, keyword.value);
        });
    } else {
        translatedText = translatedText.replace(`\{[.*]}`, '');
    }

    return translatedText;
}

export function getLanguage() {
    const savedLanguage = localStorage.getItem('language');
    return savedLanguage !== null ? savedLanguage : 'de';
}

export function setLanguage(language) {
    localStorage.setItem('language', language);
}
