import React, { useCallback } from "react";
import { Link } from "react-scroll";
import { scrollDuration } from "../config/commonConfig";
import { params } from './jsons.js'
import Particles from "react-particles";
import { loadFull } from "tsparticles";
import ReactGA from "react-ga4";
import { I18N } from "./translations/i18n";

const Services = () => {
  function safeEvent() {
    ReactGA.event({
      category: 'kontakt',
      action: 'kontakt',
      label: 'kontakt'
    })
  }


  const particlesInit = useCallback(async engine => {
    console.log(engine);
    // you can initiate the tsParticles instance (engine) here, adding custom shapes or presets
    // this loads the tsparticles package bundle, it's the easiest method for getting everything ready
    // starting from v2 you can add only the features you need reducing the bundle size
    await loadFull(engine);
  }, []);

  const particlesLoaded = useCallback(async container => {
    console.log('HELLOO')
    await console.log(container);
  }, []);

  // services details
  const services = I18N("services.servicesContent")

  return (
    <section id="services" className="section bg-white">
      <div className="container">
        {/* <Particles id="tsswdswdparticles" style={{ position: 'absolute', top: 0 }} params={params.header} init={particlesInit} loaded={particlesLoaded} /> */}
        {/* Heading */}
        <p className=" text-center mb-2 wow fadeInUp text-white">
          <span className="bg-primary text-white px-2">{I18N('services.title')}</span>
        </p>
        <h2 className="text-10 fw-600 text-center mb-5 wow fadeInUp ">
          {I18N('services.headline')}
        </h2>
        {/* Heading end*/}
        {/* style={{ paddingLeft: 30, paddingRight: 30 }} */}
        <div className="row gy-5 mt-5" >
          {services.length > 0 &&
            services.map((service, index) => (
              <div className="col-sm-6 col-lg-4 wow fadeInUp" key={index} style={{}}>
                <div className="featured-box text-center px-md-4">
                  <div className="featured-box-icon text-primary text-13">
                    {" "}
                    <i className={service.icon} />
                  </div>
                  <h3 className="text-6 fw-600 mb-3">{service.name}</h3>
                  <p className="text-muted mb-0">{service.desc}<br></br>
                    <span className="text-primary">{service.greenText ? service.greenText : ''}</span> </p>
                </div>
              </div>

            ))}

          <div style={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <Link
              className="btn bg-primary text-white rounded-0 smooth-scroll mt-5 col-sm-3 col-lg-4"
              smooth="easeInOutQuint"
              duration={scrollDuration}
              style={{ cursor: "pointer", width: 300 }}
              to="contact"
              onClick={() => safeEvent()}
            >
              {I18N('talkAboutProject')}
            </Link>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Services;
