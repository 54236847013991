export const params = {
    header: {
        "position": "relative",
        "fullScreen": {
            "enable": false,
        },
        "particles": {
            "number": {
                "value": 100,
                "density": {
                    "enable": true,
                    "value_area": 2000
                }
            },
            "color": {
                "value": ["#5CAB4F", "#559F4B"]
            },
            "shape": {
                "type": "circle",
                "stroke": {
                    "width": 0,
                    "color": "#fff"
                }
            },
            "opacity": {
                "value": 0.5,
                "random": false,
                "anim": {
                    "enable": true,
                    "speed": 0.5,
                    "opacity_min": 0.1,
                    "sync": false
                }
            },
            "size": {
                "value": 8.017060304327615,
                "random": true,
                "anim": {
                    "enable": true,
                    "speed": 12.181158184520175,
                    "size_min": 0.1,
                    "sync": true
                }
            },
            "line_linked": {
                "enable": true,
                "distance": 150,
                "color": "#fff",
                "opacity": 0.5,
                "width": 1
            },
            "move": {
                "enable": true,
                "speed": 1,
                "direction": "none",
                "random": false,
                "straight": false,
                "out_mode": "bounce",
                "bounce": false,
                "attract": {
                    "enable": false,
                    "rotateX": 600,
                    "rotateY": 1200
                }
            }
        },
        "interactivity": {
            "detect_on": "canvas",
            "events": {
                "onhover": {
                    "enable": true,
                    "mode": "repulse"
                },
                "onclick": {
                    "enable": true,
                    "mode": "push"
                },
                "resize": true
            },
            "modes": {
                "grab": {
                    "distance": 400,
                    "line_linked": {
                        "opacity": 1
                    }
                },
                "bubble": {
                    "distance": 400,
                    "size": 40,
                    "duration": 2,
                    "opacity": 8,
                    "speed": 3
                },
                "repulse": {
                    "distance": 200,
                    "duration": 0.4
                },
                "push": {
                    "particles_nb": 4
                },
                "remove": {
                    "particles_nb": 2
                }
            }
        },
        detectRetina: true,
    },
    next: {
        "name": "Gravity",
        "fullScreen": {
            "enable": true,
            "position": 'relative',
        },
        "particles": {
            "destroy": {
                "mode": "split",
                "split": {
                    "count": 1,
                    "factor": {
                        "value": {
                            "min": 4,
                            "max": 9
                        }
                    },
                    "particles": {
                        "collisions": {
                            "enable": false
                        },
                        "destroy": {
                            "mode": "none"
                        },
                        "life": {
                            "count": 1
                        }
                    }
                }
            },
            "number": {
                "value": 0
            },
            "color": {
                "value": "#ffffff"
            },
            "shape": {
                "type": "circle"
            },
            "opacity": {
                "value": 0.5,
                "random": false,
                "animation": {
                    "enable": false,
                    "speed": 1,
                    "minimumValue": 0.1,
                    "sync": false
                }
            },
            "size": {
                "value": 15,
                "random": {
                    "enable": true,
                    "minimumValue": 10
                },
                "animation": {
                    "enable": false,
                    "speed": 40,
                    "minimumValue": 0.1,
                    "sync": false
                }
            },
            "links": {
                "enable": false
            },
            "life": {
                "duration": {
                    "sync": true,
                    "value": 5
                },
                "count": 1
            },
            "move": {
                "enable": true,
                "gravity": {
                    "enable": true
                },
                "speed": 10,
                "direction": "none",
                "random": false,
                "straight": false,
                "outModes": {
                    "bottom": "split",
                    "left": "destroy",
                    "right": "destroy",
                    "top": "none"
                },
                "trail": {
                    "enable": true,
                    "fillColor": "#000000",
                    "length": 10
                }
            }
        },
        "interactivity": {
            "events": {
                "onHover": {
                    "enable": false,
                    "mode": "repulse",
                    "parallax": {
                        "enable": false,
                        "force": 60,
                        "smooth": 10
                    }
                },
                "onClick": {
                    "enable": true,
                    "mode": "push"
                }
            },
            "modes": {
                "grab": {
                    "distance": 400,
                    "links": {
                        "opacity": 1
                    }
                },
                "bubble": {
                    "distance": 400,
                    "size": 40,
                    "duration": 2,
                    "opacity": 0.8
                },
                "repulse": {
                    "distance": 200
                },
                "push": {
                    "quantity": 4
                },
                "remove": {
                    "quantity": 2
                }
            }
        },
        "background": {
            "color": "#000"
        },
        "emitters": {
            "direction": "top",
            "life": {
                "count": 0,
                "duration": 5,
                "delay": 2
            },
            "rate": {
                "delay": 0.1,
                "quantity": 1
            },
            "size": {
                "width": 0,
                "height": 0
            },
            "particles": {
                "bounce": {
                    "vertical": {
                        "value": 0.8,
                        "random": {
                            "enable": true,
                            "minimValue": 0.4
                        }
                    }
                },
                "color": {
                    "value": ["#5bc0eb", "#fde74c", "#9bc53d", "#e55934", "#fa7921"]
                },
                "links": {
                    "enable": false
                },
                "size": {
                    "value": 10,
                    "random": {
                        "enable": true,
                        "minimumValue": 5
                    }
                },
                "opacity": {
                    "value": 0.5
                },
                "move": {
                    "speed": 10,
                    "random": false
                }
            }
        }
    }
}